import React,{Component} from 'react';
import {Row,Col, Card, CardText, CardHeader, CardFooter, CardBody,CardTitle } from 'reactstrap';
import Loading from './LoadingComponent';

function RenderBook({book,isAdmin,toggleEditModal,changeSelected}) {
    if (book != null)
        return(
        <Card>
       
       <CardHeader tag="h3">{book.name} &nbsp; &nbsp; &nbsp;&nbsp;
       {isAdmin?(<span className="fa fa-pencil Option" onClick={()=>{changeSelected(book._id);toggleEditModal();}}/>):(<React.Fragment/>)}
        </CardHeader>
        <CardBody>
          <CardTitle align="right"><b>Autore :</b> {book.author}</CardTitle>
          <CardText>
              <b> ID : </b> {book._id} <br/><br/>
              <b> Casa Editrice : </b> {book.publisher} <br/><br/>
              <b> Nome Catalogo : </b> {book.cat} <br/><br/>
              <b> ISBN : </b> {book.isbn} <br/><br/>
              <b> Copie Disponibili : </b> {book.copies}<br/><br/>
              <b> Titolo Originale : </b> {book.TITOLO_ORIGINALE}    <br/><br/>          
              <b> Autore Secondario : </b> {book.AUTORE_SECONDARIO}      <br/><br/>     
              <b> Luogo : </b> {book.LUOGO}                <br/><br/>            
              <b> Anno : </b> {book.ANNO}                <br/><br/>              
              <b> N Pag : </b> {book.N_PAG}                        <br/><br/>     
              <b> SBN : </b> {book.SBN}                                 <br/><br/>
              <b> Dewey : </b> {book.DEWEY}                             <br/><br/>
              <b> Collana : </b> {book.COLLANA}                        <br/><br/>
              <b> N Catalogo : </b> {book.N_CATALOGO}                            <br/><br/>              
              <b>Descrizione : </b><br/> {book.description} <br/><br/>              
              <b>Note : </b><br/> {book.NOTE} <br/><br/>                            
      </CardText><br/>
        </CardBody>
        </Card>
        );
    else
        return(
            <div></div>
        );
        }


class BookDetail extends Component {

    constructor(props){
        super(props);
        this.state={
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
render(){
  if (this.props.isLoading) {
    return(
        <div className="container">
            <div className="row">            
                <Loading />
            </div>
        </div>
    );
}
else if (this.props.errMess) {
    return(
        <div className="container loading">
            <div className="row heading"> 
                <div className="col-12">
                    <br/><br/><br/><br/>
                    <h3>{this.props.errMess}</h3>
                </div>
            </div>
        </div>
    );
}
else
    return(

        <div className="container full">
        <div className="row heading">
          <div className="col-12">
          <br/>        <br/>
          <RenderBook book={this.props.book} isAdmin={this.props.isAdmin}
                    toggleEditModal={this.props.toggleEditModal}
                    changeSelected={this.props.changeSelected}>
              </RenderBook>

        <br/>
          </div>
        </div>
      </div>
        );
}

}

export default BookDetail;