import React from 'react';

function Footer() {
  return (
    <p className="footer fixed-bottom" align="center">
      Biblioteca TORGEIR - I Sentieri del Pensiero
    </p>
  );
}

export default Footer;
