import React, {Component} from 'react';
import Header from './HeaderComponent.js';
import Footer from './FooterComponent.js';
import Home from './HomeComponent.js';
import Booklist from './BooksComponent.js';
import Search from './SearchComponent.js';
import IlTeatroRidotto from './IlTeatroRidotto';
import LaBiblioteca from './LaBiblioteca';
import BookDetail from './BookDetailComponent.js';
import Profile from './ProfileComponent.js';
import AddBook from './AddBookComponent.js';
import History from './HistoryComponent.js';
import Issue from './IssueComponent.js';
import Return from './ReturnComponent.js';
import UserDetail from './UserDetailComponent.js';
import Stats from './StatsComponent.js';
import Log from './LogComponent.js';
import UserList from './UserListComponent.js';
import {availableCategories} from '../constants';

import {Switch,Route,Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Modal,ModalBody,ModalHeader,Button, Label, Col, Row} from 'reactstrap';
import { postBook, fetchBooks, editBook, deleteBook,loginUser, logoutUser, 
  registerUser, editUser, editPassword, postIssue, returnIssue, fetchIssues, fetchUsers} from '../redux/ActionCreators';
import { Control, LocalForm, Errors  } from 'react-redux-form';

const required = (val) => val && val.length;
const requiredNum = (val) => !!(val);
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => (val) && (val.length >= len);
const maxVal = (len) => (val) => !(val) || (val<= len);
const minVal = (len) => (val) => (val) && (val>= len);
const isNumber = (val) => !isNaN(Number(val));

const mapStateToProps= (state)=>{
  return{
    books: state.books,
    auth: state.auth,
    issues: state.issues,
    users: state.users
  };
}

const mapDispatchToProps = dispatch => ({
  fetchBooks: () => { dispatch(fetchBooks())},
  fetchIssues: (issuer) =>{ dispatch(fetchIssues(issuer))},
  fetchUsers: () => { dispatch(fetchUsers())},
  postBook: (name, author, description, isbn, cat, copies,
     publisher, AUTORE_SECONDARIO, TITOLO_ORIGINALE, ANNO,
    LUOGO, SBN, DEWEY, COLLANA, N_CATALOGO, N_PAG, NOTE) => dispatch(
    postBook(name, author, description, isbn, cat, copies,
      publisher, AUTORE_SECONDARIO, TITOLO_ORIGINALE, ANNO,
    LUOGO, SBN, DEWEY, COLLANA, N_CATALOGO, N_PAG, NOTE )),
  editBook: (_id, name, author, description, isbn, cat, copies,
    publisher, AUTORE_SECONDARIO, TITOLO_ORIGINALE, ANNO,
    LUOGO, SBN, DEWEY, COLLANA, N_CATALOGO, N_PAG, NOTE) => dispatch(
      editBook(_id, name, author, description, isbn, cat, copies,
      publisher, AUTORE_SECONDARIO, TITOLO_ORIGINALE, ANNO,
      LUOGO, SBN, DEWEY, COLLANA, N_CATALOGO, N_PAG, NOTE)),
  deleteBook: (_id) =>  dispatch(deleteBook(_id)),
  loginUser: (creds) => dispatch(loginUser(creds)),
  logoutUser: () => dispatch(logoutUser()),
  registerUser: (creds) => dispatch(registerUser(creds)),
  editUser: (_id, firstname, lastname, email) => dispatch(editUser(_id, firstname, lastname, email)),
  editPassword : (_id,username,password) => dispatch(editPassword(_id,username,password)),
  postIssue: (bookId,issuerId) => (dispatch(postIssue(bookId,issuerId))),
  returnIssue: (issueId) => (dispatch(returnIssue(issueId)))
});

class Main extends Component {
  
  componentDidMount() {
    this.props.fetchBooks();
    if(this.props.auth.isAuthenticated){
      this.props.fetchIssues(!this.props.auth.userinfo.admin);
    }
    if(this.props.auth.isAuthenticated&&this.props.auth.userinfo.admin){
      this.props.fetchUsers();
    }
  }
    constructor(props){
        super(props);
        this.state={
          isDeleteModalOpen: false,
          isEditModalOpen: false,
          selectedBook: null
        };
        this.toggleDeleteModal=this.toggleDeleteModal.bind(this);
        this.toggleEditModal=this.toggleEditModal.bind(this);
        this.changeSelected=this.changeSelected.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
      }
    
      handleSubmitEdit(values) {
        this.toggleEditModal();
        this.props.editBook(this.state.selectedBook._id, values.name, values.author,
          values.description, values.isbn, values.cat, values.copies,
          values.publisher, values.AUTORE_SECONDARIO, values.TITOLO_ORIGINALE, values.ANNO,
          values.LUOGO, values.SBN, values.DEWEY, values.COLLANA, values.N_CATALOGO, values.N_PAG,
          values.NOTE);     
        }
    
    changeSelected(_id){
      this.setState({selectedBook:this.props.books.books.filter((book)=>(book._id===(+_id)))[0]});
    }

    toggleDeleteModal(){
      this.setState({isDeleteModalOpen: !this.state.isDeleteModalOpen})
    }
    
    toggleEditModal(){
      this.setState({isEditModalOpen: !this.state.isEditModalOpen});
    }

    render(){
      const BookWithId = ({match}) => {
      let selectedBook=this.props.books.books.filter((book) => (book._id)===Number(match.params.bookId))[0]
      let notFoundErr=null;
      if(selectedBook===undefined){
      notFoundErr=("\n\n Error 404 :  Book not found");
      }  
      return(
          <BookDetail book={selectedBook}
          isLoading={this.props.books.isLoading}
          errMess={this.props.books.errMess||notFoundErr}
          toggleEditModal={this.toggleEditModal}
          changeSelected={this.changeSelected}
          isAdmin={(this.props.auth.userinfo==null)?false:(this.props.auth.userinfo.admin)}
          />
          );
      };
    
      const UserWithId = ({match}) => {
        let selectedUser=this.props.users.users.filter((user) => ((user._id)===(match.params.userId)))[0];
        let notFoundErr=null;
        if(selectedUser===undefined){
        notFoundErr=("\n\n Error 404 :  User not found");
        }  
        return(
            <UserDetail user={selectedUser}
            isLoading={this.props.users.isLoading}
            errMess={this.props.users.errMess||notFoundErr}
            />
            );
        };
   
      const PrivateRouteCommon = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
          this.props.auth.isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }} />
        )} />
      );

      const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
          this.props.auth.isAuthenticated&&this.props.auth.userinfo.admin
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }} />
        )} />
      );

      const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
          this.props.auth.isAuthenticated&&!this.props.auth.userinfo.admin
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }} />
        )} />
      );

      let uniqueIsbn= (defaultIsbn)=> (val) =>(!this.props.books.books.some((book)=>(book.isbn===val))||(val===defaultIsbn))
      let uniqueName= (defaultName)=>(val) =>(!this.props.books.books.some((book)=>(book.name===val))||(val===defaultName))

    return ( 
          <div className="App">
          <Header auth={this.props.auth} 
          loginUser={this.props.loginUser} 
          logoutUser={this.props.logoutUser}
          registerUser={this.props.registerUser}
          />
          <Switch location={this.props.location}>
                      <Route exact path='/home' component={() => <Home />} />
                      <Route exact path='/search' component={() => <Search 
                      books={this.props.books.books}
                      booksLoading={this.props.books.isLoading}
                      booksErrMess={this.props.books.errMess}
                      isSignedIn={this.props.auth.isAuthenticated}
                      isAdmin={(this.props.auth.userinfo==null)?false:(this.props.auth.userinfo.admin)}
                      toggleEditModal={this.toggleEditModal}
                      toggleDeleteModal={this.toggleDeleteModal}
                      changeSelected={this.changeSelected}
                />}
                />
                      <Route exact path='/la-biblioteca' component={() => <LaBiblioteca/>}/>
                      <Route exact path='/il-teatro-ridotto' component={() => <IlTeatroRidotto/>}/>                
                      <Route exact path='/books' component={() => <Booklist
                      books={this.props.books.books}
                      booksLoading={this.props.books.isLoading}
                      booksErrMess={this.props.books.errMess}
                      isSignedIn={this.props.auth.isAuthenticated}
                      isAdmin={(this.props.auth.userinfo==null)?false:(this.props.auth.userinfo.admin)}
                      auth={this.props.auth}
                      toggleEditModal={this.toggleEditModal}
                      toggleDeleteModal={this.toggleDeleteModal}
                      changeSelected={this.changeSelected}/>}/>
                      <Route path='/books/:bookId' component={BookWithId} />
                      <PrivateRouteCommon exact path='/profile' component={() => <Profile
                      auth={this.props.auth}
                      editUser={this.props.editUser} 
                      editPassword={this.props.editPassword}/>
                      }
                      />
                       <PrivateRouteAdmin exact path='/add_book' component={() => <AddBook
                      isAdmin={(this.props.auth.userinfo==null)?false:(this.props.auth.userinfo.admin)}
                      postBook={this.props.postBook}
                      books={this.props.books.books}
                      booksLoading={this.props.books.isLoading}
                      booksErrMess={this.props.books.errMess}
                      />
                      }/>
                      <PrivateRoute exact path='/profile' component={() => <Profile
                      auth={this.props.auth}
                      editUser={this.props.editUser} />}
                      />
                       <PrivateRoute exact path='/history' component={() => <History
                      issues={this.props.issues}
                      auth={this.props.auth}
                     />}
                      />
                       <PrivateRouteAdmin exact path='/logs' component={() => <Log
                      issues={this.props.issues}
                     />}
                      />
                         <PrivateRouteAdmin exact path='/list_users' component={() => <UserList
                      users={this.props.users.users.filter((user)=>(!user.admin))}
                      usersLoading={this.props.users.isLoading}
                      usersErrMess={this.props.users.errMess}
                     />}
                      />
                         <PrivateRouteAdmin exact path='/list_admins' component={() => <UserList
                      users={this.props.users.users.filter((user)=>(user.admin))}
                      usersLoading={this.props.users.isLoading}
                      usersErrMess={this.props.users.errMess}
                     />}
                      />
                       <PrivateRouteAdmin exact path='/issue' component={() => <Issue
                      auth={this.props.auth}
                      books={this.props.books.books}
                      booksLoading={this.props.books.isLoading}
                      booksErrMess={this.props.books.errMess}
                      users={this.props.users.users}
                      usersLoading={this.props.users.isLoading}
                      usersErrMess={this.props.users.errMess}
                      postIssue={this.props.postIssue}
                       />} />
                      <PrivateRouteAdmin exact path='/return' component={() => <Return
                      issues={this.props.issues}
                      auth={this.props.auth}
                      returnIssue={this.props.returnIssue}
                     />} />
                      <PrivateRouteAdmin path='/users/:userId' component={UserWithId}/>
                      <PrivateRouteAdmin path='/stats' component={() => <Stats
                      issues={this.props.issues}
                      books={this.props.books.books}
                      booksLoading={this.props.books.isLoading}
                      booksErrMess={this.props.books.errMess}
                      users={this.props.users.users}
                      usersLoading={this.props.users.isLoading}
                      usersErrMess={this.props.users.errMess}
                     />}/>
                      <Redirect to="/home"/>
          </Switch>
        <br/>
        <Footer/>
        <Modal isOpen={this.state.isDeleteModalOpen} toggle={this.toggleDeleteModal}>
                     <ModalHeader toggle={this.toggleDeleteModal}>
                         Confirm Deletion
                     </ModalHeader>
                     <ModalBody>
                       Book details : <br/><br/>
                        Name : {this.state.selectedBook?this.state.selectedBook.name:''} <br/>
                        Authors : {this.state.selectedBook?this.state.selectedBook.author:''} <br/>
                        ISBN Number : {this.state.selectedBook?this.state.selectedBook.isbn:''} <br/>
                        Available Copies : {this.state.selectedBook?this.state.selectedBook.copies:''} <br/> <br/>
                        Are you sure you wish to delete this book ? <br/><br/>
         <Button color="danger" onClick={()=>{
           this.props.deleteBook(this.state.selectedBook._id);
           this.toggleDeleteModal();}}>Yes</Button>{' '}  
         <Button color="warning" onClick={()=>{
           this.toggleDeleteModal();
         }}>No</Button>
                     </ModalBody>
          </Modal>
          {this.state.selectedBook?(
                 <Modal size='lg' isOpen={this.state.isEditModalOpen} toggle={this.toggleEditModal}>
                     <ModalHeader toggle={this.toggleEditModal}>
                         Edit a book
                     </ModalHeader>
                     <ModalBody>
                    <LocalForm onSubmit={(values) => this.handleSubmitEdit(values)}>
                    <Row className="form-group">
                                <Label htmlFor="name" md={3}>Titolo</Label>
                                <Col md={9}>
                                    <Control.text model=".name" id="name" name="name"
                                        defaultValue={this.state.selectedBook.name}
                                        className="form-control"
                                        validators={{
                                          required, minLength: minLength(3)
                                        }}
                                         />
                                    <Errors
                                        className="text-danger"
                                        model=".name"
                                        show="touched"
                                        messages={{
                                          required: 'Required',
                                          minLength: ' Must be greater than 2 characters'
                                        }}
                                     />
                                </Col>
                            </Row>                    
                            <Row className="form-group">
                                <Label htmlFor="author" md={3}>Autore</Label>
                                <Col md={9}>
                                    <Control.text model=".author" id="author" name="author"
                                        defaultValue={this.state.selectedBook.author}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="publisher" md={3}>Casa Editrice</Label>
                                <Col md={9}>
                                    <Control.text model=".publisher" id="publisher" name="publisher"
                                        defaultValue={this.state.selectedBook.publisher}
                                        className="form-control"
                                    />
                                </Col>                              
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="isbn" md={3}>ISBN</Label>
                                <Col md={9}>
                                    <Control.text model=".isbn" id="isbn" name="isbn"
                                        defaultValue={this.state.selectedBook.isbn}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>                                        
                        <Row className="form-group">
                              <Label md={3} htmlFor="cat">Nome Catalogo</Label>
                              <Col md={9}>                              
                              <Control.select model=".cat" id="cat" className="form-control" defaultValue={this.state.selectedBook.cat}>
                                {availableCategories.map((c) => <option key={c}>{c}</option>)}
                              </Control.select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                          <Label htmlFor="copies" md={3}> Copie Disponibili</Label>
                          <Col md={9}>
                              <Control.text model=".copies" id="copies" name="copies"
                                  defaultValue={this.state.selectedBook.copies}
                                  className="form-control"
                                  validators={{
                                    requiredNum, minVal: minVal(0), isNumber
                                  }}
                                    />
                              <Errors
                                  className="text-danger"
                                  model=".copies"
                                  messages={{
                                    requiredNum: 'Required',
                                    minVal: ' Must be a positive number or zero',
                                    isNumber: ' Must be a number'
                                  }}
                                />
                          </Col>
                        </Row> 
                        <Row className="form-group">
                                <Label htmlFor="TITOLO_ORIGINALE" md={3}>Titolo Originale</Label>
                                <Col md={9}>
                                    <Control.text model=".TITOLO_ORIGINALE" id="TITOLO_ORIGINALE" name="TITOLO_ORIGINALE"
                                        placeholder="Original title of book"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.TITOLO_ORIGINALE}     
                                    />
                                </Col>
                        </Row>
                        <Row className="form-group">  
                            <Label htmlFor="AUTORE_SECONDARIO" md={3}>Autore Secondario </Label>
                                <Col md={9}>
                                    <Control.text model=".AUTORE_SECONDARIO" id="AUTORE_SECONDARIO" name="AUTORE_SECONDARIO"
                                        placeholder="Name of secondary authors"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.AUTORE_SECONDARIO}     
                                    />
                                </Col>     
                          </Row>
                          <Row className="form-group">                                                 
                            <Label htmlFor="LUOGO" md={3}>Luogo</Label>
                                <Col md={9}>
                                    <Control.text model=".LUOGO" id="LUOGO" name="LUOGO"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.LUOGO}     
                                    />
                                </Col>                            
                        </Row>
                        <Row className="form-group">
                                <Label htmlFor="ANNO" md={3}>Anno</Label>
                                <Col md={9}>
                                    <Control.text model=".ANNO" id="ANNO" name="ANNO"
                                        className="form-control"       
                                        defaultValue={this.state.selectedBook.ANNO}                
                                    />
                                </Col>
                          </Row>
                          <Row className="form-group">
                                <Label htmlFor="N_PAG" md={3}> N Pag</Label>
                                <Col md={9}>
                                    <Control.text model=".N_PAG" id="N_PAG" name="N_PAG"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.N_PAG}     
                                    />
                                </Col>
                            </Row>                        
                        <Row className="form-group">  
                            <Label htmlFor="SBN" md={3}>SBN </Label>
                                <Col md={9}>
                                    <Control.text model=".SBN" id="SBN" name="SBN"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.SBN}     
                                    />
                                </Col>      
                          </Row>
                          <Row className="form-group">                                                
                            <Label htmlFor="DEWEY" md={3}>Dewey</Label>
                                <Col md={9}>
                                    <Control.text model=".DEWEY" id="DEWEY" name="DEWEY"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.DEWEY}     
                                    />
                                </Col>                            
                        </Row>                                 
                        <Row className="form-group">  
                            <Label htmlFor="COLLANA" md={3}>Collana</Label>
                                <Col md={9}>
                                    <Control.text model=".COLLANA" id="COLLANA" name="COLLANA"
                                        className="form-control"
                                        defaultValue={this.state.selectedBook.COLLANA}     
                                    />
                                </Col> 
                          </Row>
                          <Row className="form-group">                                                      
                            <Label htmlFor="N_CATALOGO" md={3}>N Catalogo</Label>
                                <Col md={9}>
                                    <Control.text model=".N_CATALOGO" id="N_CATALOGO" name="N_CATALOGO"
                                        className="form-control" 
                                        defaultValue={this.state.selectedBook.N_CATALOGO}     
                                    />
                                </Col>                            
                        </Row>        
                        <Row className="form-group">
                          <Label htmlFor="description" md={3}>Descrizione</Label>
                          <Col md={9}>
                              <Control.textarea model=".description" id="description" name="description"
                                  rows="6"
                                  defaultValue={this.state.selectedBook.description}
                                  className="form-control" />
                          </Col>
                         </Row>
                         <Row className="form-group">
                                <Label htmlFor="NOTE" md={3}>Note</Label>
                                <Col md={9}>
                                    <Control.textarea model=".NOTE" id="NOTE" name="NOTE"
                                        rows="6"
                                        placeholder="Some notes about the book"
                                        defaultValue={this.state.selectedBook.NOTE}                                        
                                        className="form-control" />
                                </Col>
                        </Row>                        
                          <Row>
                          <Col className="ml-auto mr-auto">
                        <Button type="submit" className="bg-primary">
                            Submit
                        </Button>
                        </Col>
                        </Row>
                    </LocalForm>
                </ModalBody>
               
          </Modal>):(<React.Fragment/>)}

          </div>
           );     
    }
    }

    export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Main));

