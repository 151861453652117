import React from 'react';

function LaBiblioteca(props) {
  return (
    <div className="container mt-4 home text-center align-self-center darkbg">
      <br />
      <br />
      <h3>La Biblioteca</h3>
      <br />
      <p>
        La Biblioteca è stata fortemente voluta dallo storico direttore nonché
        regista e fondatore del Teatro Ridotto Renzo Filippetti (insieme a Lina
        Della Rocca) il quale, in seguito alla morte dell'attore e amico
        dell'Odin Teatret Torgeir Wethal, lanciò una richiesta di donazione di
        libri a livello internazionale, con volumi di provenienza da ogni parte
        del mondo. Oggi, a distanza di 11 anni, i libri di questa raccolta sono
        stati catalogati secondo i criteri nazionali grazie al supporto di tanti
        giovani studenti, al prezioso lavoro di archiviazione, di scelta dei
        volumi e di digitalizzazione a cura di Simone Azzu e grazie al Professor
        Gerardo Guccini dell'Università di Bologna.   In seguito al complesso
        lavoro di archiviazione e catalogazione dei volumi, la Biblioteca ha
        potuto attuare un processo di ufficializzazione ed apertura al pubblico.
        La Biblioteca “Torgeir - I Sentieri del pensiero” sarà aperta e resa
        disponibile, con le canoniche modalità di prestito, all’intera
        cittadinanza. Alcuni dei volumi più rari saranno resi accessibili in via
        digitale, come parte di un processo di conservazione e di valorizzazione
        della Biblioteca. La digitalizzazione renderà inoltre accessibile una
        parte dei volumi della Biblioteca a tutti coloro che desiderano
        consultare la biblioteca a distanza.
      </p>
      <br />
      <h5>Indirizzo</h5>
      <p>Via Marco Emilio Lepido, 255, 40132 Bologna BO +39 3464905774</p>
    </div>
  );
}

export default LaBiblioteca;
