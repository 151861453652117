import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Badge,
  ListGroupItem,
  ListGroup,
} from 'reactstrap';
import Loading from './LoadingComponent.js';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../utils';
import { availableCategories } from '../constants';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      author: '',
      publisher: '',
      cat: [...availableCategories],
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const options = this.state.cat;
    let pos = -1;
    console.log(e.target.value);
    if (e.target.checked) {
      options.push(e.target.value);
    } else {
      let i = 0;
      for (; i < options.length; i++) {
        if (options[i] === e.target.value) {
          pos = i;
          break;
        } else {
        }
      }
      options.splice(pos, 1);
    }
    this.setState({ cat: options });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    var colors = ['warning', 'danger', 'success', 'info', 'secondary'];
    var nameRegex = new RegExp(this.state.name, 'i');
    var authorRegex = new RegExp(this.state.author, 'i');
    var publisherRegex = new RegExp(this.state.publisher, 'i');
    const list = this.props.books.map((book) => {
      let category_matched = this.state.cat.some(
        (category) => category === book.cat
      );
      if (!book.author) book.author = '';
      if (!book.name) book.name = '';
      if (!book.publisher) book.publisher = '';
      if (
        book.name.search(nameRegex) !== -1 &&
        book.author.search(authorRegex) !== -1 &&
        book.publisher.search(publisherRegex) !== -1 &&
        category_matched
      )
        return (
          <ListGroupItem key={book._id}>
            <Link to={`/books/${book._id}`}>
              <b>{`${book.name}  `}</b>
            </Link>
            <Badge color={colors[book.cat.length % 5]} pill>
              {' '}
              {book.cat}
            </Badge>
            {this.props.isAdmin ? (
              <React.Fragment>
                &nbsp; &nbsp;
                <span
                  onClick={() => {
                    this.props.changeSelected(book._id);
                    this.props.toggleEditModal();
                  }}
                  className="Option fa fa-pencil"
                />
                {'  '}&nbsp; &nbsp;{' '}
                <span
                  onClick={() => {
                    this.props.changeSelected(book._id);
                    this.props.toggleDeleteModal();
                  }}
                  className="Option fa fa-trash"
                />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            <br /> <h6 className="ml-auto"> ID : {`    ${book._id}`}</h6>
            <h6> Autore : {`    ${book.author}`}</h6>
            <h6> Casa Editrice: {book.publisher}</h6>
            <h6> Copie Disponibili: {book.copies}</h6>
          </ListGroupItem>
        );
      else return <React.Fragment />;
    });
    if (this.props.booksLoading) {
      return (
        <div className="container loading">
          <div className="row">
            <Loading />
          </div>
        </div>
      );
    } else if (this.props.booksErrMess) {
      return (
        <div className="container loading">
          <div className="row heading">
            <div className="col-12">
              <br />
              <br />
              <br />
              <br />
              <h3>{this.props.booksErrMess}</h3>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container loading">
          <div className="row">
            <div className="col-12 heading">
              <h3 align="center">Cerca un titolo</h3>
              <Form>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="name">Titolo</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        placeholder="Enter name of the book"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="author">Autore</Label>
                      <Input
                        type="text"
                        name="author"
                        id="author"
                        value={this.state.author}
                        onChange={(e) => {
                          this.setState({ author: e.target.value });
                        }}
                        placeholder="Enter name of author"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="publisher">Casa Editrice</Label>
                      <Input
                        type="text"
                        name="publisher"
                        id="publisher"
                        value={this.state.publisher}
                        onChange={(e) => {
                          this.setState({ publisher: e.target.value });
                        }}
                        placeholder="Enter name of publisher"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={2}>
                    Nome Catalogo :{' '}
                  </Col>
                </Row>
                <FormGroup check>
                  <Row>
                    {availableCategories.map((cat) => (
                      <Col xs={6} md={3}>
                        <Label check for={cat}>
                          <Input
                            defaultChecked
                            type="checkbox"
                            onChange={this.onChange}
                            id={cat}
                            value={cat}
                            name="cat"
                          />
                          {toTitleCase(cat)}
                        </Label>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
              </Form>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <br />
              <ListGroup>{list}</ListGroup>
            </div>
          </div>
          <br />
        </div>
      );
    }
  }
}

export default Search;
