import React,{Component} from 'react';
import { Button, Form, FormGroup, Label, Input, Col,Row } from 'reactstrap';
import Loading from './LoadingComponent';


class Issue extends Component {

    constructor(props){
        super(props);
        let useroptions=props.users.filter((user)=>(!user.admin));
        this.state={
        _id: props.books.length?props.books[0]._id:0,
        email: useroptions.length?useroptions[0].email:''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
      }

render(){
    if (this.props.booksLoading||this.props.usersLoading) {
        return(
            <div className="container">
                <div className="row">            
                    <Loading />
                </div>
            </div>
        );
    }
    else if (this.props.booksErrMess) {
        return(
            <div className="container loading">
                <div className="row heading"> 
                    <div className="col-12">
                        <br/><br/><br/><br/>
                        <h3>{this.props.booksErrMess}</h3>
                    </div>
                </div>
            </div>
        );
    }
    else if (this.props.usersErrMess) {
        return(
            <div className="container loading">
                <div className="row heading"> 
                    <div className="col-12">
                        <br/><br/><br/><br/>
                        <h3>{this.props.usersErrMess}</h3>
                    </div>
                </div>
            </div>
        );
    }
    else if(!this.props.books.length) {
        return (
            <div className="container loading">
                <div className="row heading text-center"> 
                    <div className="col-12">
                        <br/><br/><br/><br/>
                        <h3>No book added yet. Please add a book first.</h3>
                    </div>
                </div>
            </div>
        );
    }
    else
   {
    // To just get list of the non-admin users
    let useroptions=this.props.users.filter((user)=>(!user.admin));
    if(!useroptions.length) {
        return (
            <div className="container loading">
                <div className="row heading text-center"> 
                    <div className="col-12">
                        <br/><br/><br/><br/>
                        <h3>No library user added yet. Please register a user first.</h3>
                    </div>
                </div>
            </div>
        );
    }
    const defaultUser=useroptions[0];
    useroptions= useroptions.map((user,index)=>(<option 
    key={user.email}>{user.email}</option>))       
    const bookoptions= this.props.books.map((book,index)=>(<option 
    key={book._id.toString()}>{book._id}</option>));
    const defaultBook=this.props.books[0];
    console.log(defaultBook);
    return (
    <div className="container full">
    <div className="row justify-content-center heading">
    <div className="col-12">
  <h3 align="center">  Issue book</h3>
  </div>
    </div>
    <div className="row row-content justify-content-center" style={{display: 'inline-block', minWidth: '80vw'}}>
    <Form onSubmit={(e) => {
        let bookid=this.props.books.filter((book)=>(book._id===this.state._id))[0]._id
        let issuerid=this.props.users.filter((user)=>(user.email===this.state.email))[0]._id;
        this.props.postIssue(bookid,issuerid);
        e.preventDefault();
    }}>

        <FormGroup row>
          <Label htmlFor="_id"> Book ID </Label>
            <Input type="select" defaultValue={defaultBook.name} name="_id" id="_id" className="form-control" onInput={(e)=>{this.setState({_id: Number(e.target.value)})}}>
                  {bookoptions}
            </Input>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="email"> Email of issuer </Label>
            <Input type="select" id="email" 
                   className="form-control" onInput={(e)=>{this.setState({email: e.target.value})}}>
                   {useroptions}
            </Input>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="name"> Titolo </Label>
             <Input type="text" id="name" name="name"
                    defaultValue={defaultBook.name}
                    value={this.props.books.filter((book)=>(book._id==this.state._id))[0].name}
                    className="form-control" disabled/>
        </FormGroup>
        <FormGroup row>
          <Label htmlFor="author"> Autore </Label>
            <Input type="text" id="author" name="author"
                   defaultValue={defaultBook.author}
                   value={this.props.books.filter((book)=>(book._id===this.state._id))[0].author}
                    className="form-control" disabled/>
         </FormGroup>
         <FormGroup row>
          <Label htmlFor="name_issuer"> Name of issuer </Label>
            <Input type="text" id="name_issuer" name="name_issuer"
                   placeholder="Name of issuer" 
                   defaultValue={defaultUser.firstname+' '+defaultUser.lastname}
                   value={!this.state.email?''
                   :this.props.users.filter((user)=>(user.email===this.state.email))[0].firstname+' '+
                   this.props.users.filter((user)=>(user.email===this.state.email))[0].lastname}
                    className="form-control" disabled/>
         </FormGroup>
         <FormGroup row>
          <Label htmlFor="username"> Username of issuer </Label>
            <Input type="text" id="username" name="username"
                   placeholder="Username of issuer" 
                   defaultValue={defaultUser.username}
                   value={!this.state.email?''
                   :this.props.users.filter((user)=>(user.email===this.state.email))[0].username}
                    className="form-control" disabled/>
         </FormGroup>
         <Row className="align-self-center">
            <Col className="text-center">
              <Button type="submit" className="bg-primary">
                            Submit
               </Button>
            </Col>
        </Row>
      </Form>
     </div>
     <br/>
    </div>
 );

}
}
}

export default Issue;