import React from 'react';

function IlTeatroRidotto(props) {
  return (
    <div className="container mt-4 home text-center align-self-center darkbg">
      <br />
      <br />
      <h3>Il Teatro Ridotto</h3>
      <br />
      <p>
        Il Teatro Ridotto si costituisce nel 1983 come laboratorio permanente di
        ricerca sull’arte dell’attore. Le persone che ne fanno parte sono
        autodidatti: nessuno di loro ha frequentato prima accademie, scuole
        teatrali, teatri. Fin dall’inizio il lavoro di ricerca si concentra
        sulle possibilità fisiche e vocali dell’ attore, sulle leggi che
        regolano il “bios” del suo comportamento scenico. I primi anni di lavoro
        sono dedicati all’allenamento e alla preparazione tecnica, non avendo
        spettacoli, il gruppo inventa diversi modi di essere presente in forma
        pubblica: nascono così i primi progetti culturali e i primi scambi. Non
        avendo nessuna tradizione scenica alle spalle, il gruppo ha creato una
        propria identità artistica e culturale attraverso lo scambio con altre
        culture: ha iniziato così un lavoro di ricerca sull’oriente del teatro.
        Decisivo per la nostra formazione teatrale è stato l’incontro con
        Eugenio Barba e Iben Nagel Rasmussen dell’Odin Teatret: in diversi modi
        e in diversi periodi i componenti del gruppo hanno lavorato con loro. I
        principi sviluppati nel lavoro di training vengono ampliati da tecniche
        di improvvisazione, composizione e montaggio, dando vita a spettacoli
        sia all’aperto che al chiuso; sempre cercando un unità fra il lavoro
        dell’attore e le tecniche di montaggio dell’attenzione dello spettatore.
      </p>
    </div>
  );
}

export default IlTeatroRidotto;
