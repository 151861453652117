import React,{Component} from 'react';
import {Button, Label, Col, Row} from 'reactstrap';
import { Control, LocalForm, Errors  } from 'react-redux-form';
import Loading from './LoadingComponent';
import {availableCategories} from '../constants';

const required = (val) => val && val.length;
const requiredNum = (val) => !!(val);
const minLength = (len) => (val) => (val) && (val.length >= len);
const minVal = (len) => (val) => (val) && (val>= len);
const isNumber = (val) => !isNaN(Number(val));

class AddBook extends Component {

    constructor(props){
        super(props);
        this.state={
        }

    }
    
    componentDidMount() {
        window.scrollTo(0, 0)
      }

render(){

    if (this.props.booksLoading) {
        return(
            <div className="container">
                <div className="row">            
                    <Loading />
                </div>
            </div>
        );
    }
    else if (this.props.booksErrMess) {
        return(
            <div className="container loading">
                <div className="row heading"> 
                    <div className="col-12">
                        <br/><br/><br/><br/>
                        <h3>{this.props.booksErrMess}</h3>
                    </div>
                </div>
            </div>
        );
    }
    else
 return (
    <div className="container">
    <div className="row justify-content-center heading">
    <div className="col-12">
  <h3 align="center">  Add a book</h3>
  </div>
    </div>
    <div className="row row-content justify-content-center" style={{minWidth: '85vw',display: 'inline-block'}}>
    <LocalForm onSubmit={(values) => {
        this.props.postBook(
            values.name, 
            values.author, 
            values.description, 
            values.isbn, 
            values.cat,
            values.copies,
            values.publisher,
            values.AUTORE_SECONDARIO,
            values.TITOLO_ORIGINALE,
            values.ANNO,
            values.LUOGO,
            values.SBN,
            values.DEWEY,
            values.COLLANA,
            values.N_CATALOGO,
            values.N_PAG,
            values.NOTE            
            );
    }}>
                    <Row className="form-group">
                                <Label htmlFor="name" md={2}>Titolo </Label>
                                <Col md={10}>
                                    <Control.text model=".name" id="name" name="name"
                                        placeholder="Name of book"
                                        className="form-control"
                                        validators={{
                                            required, minLength: minLength(3)
                                        }}
                                         />
                                    <Errors
                                        className="text-danger"
                                        model=".name"
                                        show="touched"
                                        messages={{
                                            required: 'Required',
                                            minLength: ' Must be greater than 2 characters'
                                        }}
                                     />
                                </Col>
                            </Row>
                            <Row className="form-group">  
                            <Label htmlFor="author" md={2}>Autore </Label>
                                <Col md={4}>
                                    <Control.text model=".author" id="author" name="author"
                                        placeholder="Name of authors"
                                        className="form-control"
                                    />
                                </Col>
                            <Label htmlFor="publisher" md={2}>Casa Editrice</Label>
                                <Col md={4}>
                                    <Control.text model=".publisher" id="publisher" name="publisher"
                                        placeholder="Publisher of book"
                                        className="form-control"
                                    />
                                </Col>                            
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="isbn" md={2}>ISBN</Label>
                                <Col md={4}>
                                    <Control.text model=".isbn" id="isbn" name="isbn"
                                        placeholder="ISBN of book"
                                        className="form-control"
                                    />
                                </Col>
                                <Label htmlFor="copies" md={2}> Copie Disponibili</Label>
                                <Col md={4}>
                                    <Control.text model=".copies" id="copies" name="copies"
                                        placeholder="Number of copies available"
                                        className="form-control"
                                        validators={{
                                            requiredNum, minVal: minVal(0), isNumber
                                        }}
                                         />
                                    <Errors
                                        className="text-danger"
                                        model=".copies"
                                        show="touched"
                                        messages={{
                                            requiredNum: 'Required',
                                            minVal: ' Must be a positive number or zero',
                                            isNumber: ' Must be a number'
                                        }}
                                     />
                                </Col>
                            </Row>
                        <Row className="form-group">
                            <Label htmlFor="cat" md={2}>Nome Catalogo</Label>
                            <Col md={10}>
                                <Control.select defaultValue={availableCategories[0]} model=".cat" id="cat" name="cat" className="form-control">
                                {availableCategories.map((c) => <option key={c}>{c}</option>)} 
                                </Control.select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                                <Label htmlFor="TITOLO_ORIGINALE" md={2}>Titolo Originale</Label>
                                <Col md={10}>
                                    <Control.text model=".TITOLO_ORIGINALE" id="TITOLO_ORIGINALE" name="TITOLO_ORIGINALE"
                                        placeholder="Original title of book"
                                        className="form-control"
                                    />
                                </Col>
                        </Row>
                        <Row className="form-group">  
                            <Label htmlFor="AUTORE_SECONDARIO" md={2}>Autore Secondario </Label>
                                <Col md={4}>
                                    <Control.text model=".AUTORE_SECONDARIO" id="AUTORE_SECONDARIO" name="AUTORE_SECONDARIO"
                                        placeholder="Name of secondary authors"
                                        className="form-control"
                                    />
                                </Col>                                                      
                            <Label htmlFor="LUOGO" md={2}>Luogo</Label>
                                <Col md={4}>
                                    <Control.text model=".LUOGO" id="LUOGO" name="LUOGO"
                                        className="form-control"
                                    />
                                </Col>                            
                        </Row>
                        <Row className="form-group">
                                <Label htmlFor="ANNO" md={2}>Anno</Label>
                                <Col md={4}>
                                    <Control.text model=".ANNO" id="ANNO" name="ANNO"
                                        className="form-control"                  
                                    />
                                </Col>
                                <Label htmlFor="N_PAG" md={2}> N Pag</Label>
                                <Col md={4}>
                                    <Control.text model=".N_PAG" id="N_PAG" name="N_PAG"
                                        className="form-control"
                                    />
                                </Col>
                            </Row>                        
                        <Row className="form-group">  
                            <Label htmlFor="SBN" md={2}>SBN </Label>
                                <Col md={4}>
                                    <Control.text model=".SBN" id="SBN" name="SBN"
                                        className="form-control"
                                    />
                                </Col>                                                      
                            <Label htmlFor="DEWEY" md={2}>Dewey</Label>
                                <Col md={4}>
                                    <Control.text model=".DEWEY" id="DEWEY" name="DEWEY"
                                        className="form-control"
                                    />
                                </Col>                            
                        </Row>                                 
                        <Row className="form-group">  
                            <Label htmlFor="COLLANA" md={2}>Collana</Label>
                                <Col md={4}>
                                    <Control.text model=".COLLANA" id="COLLANA" name="COLLANA"
                                        className="form-control"
                                    />
                                </Col>                                                      
                            <Label htmlFor="N_CATALOGO" md={2}>N Catalogo</Label>
                                <Col md={4}>
                                    <Control.text model=".N_CATALOGO" id="N_CATALOGO" name="N_CATALOGO"
                                        className="form-control"
                                    />
                                </Col>                            
                        </Row>                                                                                                
                        <Row className="form-group">
                                <Label htmlFor="description" md={2}>Descrizione</Label>
                                <Col md={10}>
                                    <Control.textarea model=".description" id="description" name="description"
                                        rows="6"
                                        placeholder="Some description of the book"
                                        className="form-control" />
                                </Col>
                        </Row>
                        <Row className="form-group">
                                <Label htmlFor="NOTE" md={2}>Note</Label>
                                <Col md={10}>
                                    <Control.textarea model=".NOTE" id="NOTE" name="NOTE"
                                        rows="6"
                                        placeholder="Some notes about the book"
                                        className="form-control" />
                                </Col>
                        </Row>
                        <Row className="align-self-center">
                            <Col className="text-center">
                                <Button type="submit" className="bg-primary">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </LocalForm>
                    </div>
                <br/>
    </div>
 );

}

}

export default AddBook;