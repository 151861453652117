import React, { Component } from 'react';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container mt-4 home text-center align-self-center">
        <br />
        <br />
        <br />
        <div className="row mt-3 darkbg text-center justify-content-center">
          <h1 align="center">
            {' '}
            Biblioteca TORGEIR
            <br />
            <i>I Sentieri del Pensiero</i>
          </h1>
        </div>
        <div className="row darkbg justify-content-center">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h6>
            La biblioteca del teatro ridotto
            <br />
            <br />{' '}
          </h6>
        </div>
        <div className="row darkbg justify-content-center">
          Inauguriamo la Biblioteca Torgeir il 8 aprile 2022, al Teatro
          Ridotto, dalle 10.00 alle 18.00!
          <br />
          Dopo il primo ciclo di incontri in streaming di maggio 2021, la
          Biblioteca Torgeir
          <br />
          verrà finalmente inaugurata nelle giornate del 2, 3 e 4 dicembre.
          <br />
          Saranno tre giorni di incontri, spettacoli, conferenze, masterclass e
          seminari: <br />
          una grande festa, fra il Teatro Ridotto e il DAMSLab / La Soffitta -
          Università di Bologna.
          <br />
          <br />
          <br />
          Il Teatro Ridotto è orgoglioso di poter offrire alla cittadinanza del
          Comune di Bologna
          <br /> la Biblioteca “Torgeir – I Sentieri del Pensiero”, progetto
          unico nel territorio, <br />
          che riesce ad armonizzare le necessità e i desideri della comunità
          studentesca e accademica, <br />
          degli artisti e degli abitanti dei quartieri limitrofi.
        </div>
      </div>
    );
  }
}

export default Home;
